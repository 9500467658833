"use client";

import Link from "next/link";
import * as React from "react";
import messages from "~/resources/messages/en-US/app/footer.json";

interface IFooterSiteSelfLinksProps {}

const FooterSiteSelfLinks: React.FC<IFooterSiteSelfLinksProps> = () => {
	return (
		<div className="grid content-center grid-cols-1 mx-6 text-sm sm:flex sm:flex-row sm:justify-center">
			<div className="text-center hover:underline">
				<Link
					href="/dei"
					onMouseDown={e => e.preventDefault()}
					title={messages.footer.deiLinkTooltip.value}
				>
					{messages.footer.deiLinkCaption.value}
				</Link>
			</div>
			<div className="text-center sm:pl-4 sm:ml-4 sm:border-l-2 hover:underline">
				<Link
					href="/jobs/veterans"
					onMouseDown={e => e.preventDefault()}
					title={messages.footer.veteransLinkTooltip.value}
				>
					{messages.footer.veteransLinkCaption.value}
				</Link>
			</div>
		</div>
	);
};
export default FooterSiteSelfLinks;
