"use client";
import Link from "next/link";
import { useCallback, useEffect, useState } from "react";
import messages from "~/resources/messages/en-US/components/privacyBanner/main.json";

export interface IPrivacyBannerProps {
	privacyPageUrl: string;
	siteKey: string;
}

const PrivacyBanner: React.FC<IPrivacyBannerProps> = ({ privacyPageUrl, siteKey }) => {
	// https://medium.com/@kurucaner/using-localstorage-to-persist-with-react-f89152ed0743

	const [hide, setHide] = useState(true);

	const acknowledgeCallback = useCallback(() => {
		window.localStorage.setItem(`privacy_banner_hide_${siteKey}`, "1");
		setHide(true);
	}, [setHide, siteKey]);

	useEffect(() => {
		const hideFromStorage = window.localStorage.getItem(`privacy_banner_hide_${siteKey}`);
		if (hideFromStorage !== "1") {
			setHide(false);
		}
	}, [setHide, siteKey]);

	if (hide) {
		return null;
	}

	const bannerMessageLines = `${messages.privacyBannerMain.bannerMessage.value}`.split("{0}");
	return (
		<div className="fixed bottom-0 left-0 right-0 z-[5] bg-white drop-shadow-[0_-1px_1px_rgba(0,0,0,0.1)] flex flex-col md:flex-row justify-between p-8 items-center gap-4">
			<div className="text-lg font-bold text-center font-title">
				{bannerMessageLines[0]}
				<Link
					href={privacyPageUrl}
					title={messages.privacyBannerMain.privacyPolicyLinkTooltip.value}
					id={`privacy_banner_view_privacy_policy`}
					className={`font-title text-epic-light-red hover:underline focus-within:underline text-lg transition-[text-decoration-color] decoration-transparent hover:decoration-current focus-within:decoration-current duration-300 ease-in-out`}
				>
					{`${messages.privacyBannerMain.privacyPolicyLinkCaption.value}`.replaceAll(" ", "\u00A0")}
					{/* Add non-breaking space */}
				</Link>
				{bannerMessageLines[1]}
			</div>
			<button
				onClick={acknowledgeCallback}
				title={messages.privacyBannerMain.privacyPolicyBannerAcknowledgeButtonTooltip.value}
				className={`block font-title font-semibold text-lg text-white bg-epic-light-red px-4 py-2 border`}
			>
				{messages.privacyBannerMain.privacyPolicyBannerAcknowledgeButtonCaption.value}
			</button>
		</div>
	);
};
export default PrivacyBanner;
